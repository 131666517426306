import React from 'react';
import { Container, Row, Col, Accordion } from 'react-bootstrap';
import styled from 'styled-components';
import AccordionRow from '../components/AccordionRow';

const Styles = styled.div`
  margin-top: 90px;
  h1 {
    color: white;
    font-size: 100px;
  }
`;

const ServicesSection = styled.section`
  padding-top: 50px;

  h1 {
    text-align: center;
    font-family: orbitron;
    font-size: 45px;
    color: white;
    margin-bottom: 50px;
  }

  p {
    color: white;
    font-family: Roboto;
    font-size: 16px;
    text-align: justify;
  }

  .orange {
    color: rgb(232, 150, 42);
  }
`;

const ServicesInfoSection = styled.section`
  padding-top: 50px;
  padding-bottom: 50px;

  p {
    color: white;
    font-family: Roboto;
    font-size: 16px;
  }
`;

const Services = () => {
  const accordion_row = [
    {
      title: 'Contract Negotiation',
      desc:
        'We procure the best possible contracts for our clients while making sure they are protected by contract terms.',
    },
    {
      title: 'Legal Protection and Advice (Law Firm)',
      desc:
        'Our legal counsel ensures the protection of our agency and clients in the case of any legal disputes with clubs.',
    },
    {
      title: 'Advisory',
      desc:
        'We constantly provide advice and feedback to our clients on their play on the court and for any matters necessary off of it.',
    },
    {
      title: 'Marketing/Branding',
      desc:
        'We help positively brand our clients through social media and when the opportunies arise, help in procuring endorsements.',
    },
    {
      title: 'Post-Career Planning',
      desc:
        'If our clients wish to continue working in basketball after their playing careers, we help them in building connections and seeking opportunites in the industry.',
    },
  ];
  return (
    <Styles>
      <Container>
        <ServicesSection>
          <Row>
            <Col md={12}>
              <h1>
                Services <span className="orange">__</span>
              </h1>
              <p>
                FBM understands that each client has a unique process and
                pathway that must be guided and managed to reach success. Our
                basketball expertise, services, and business strategies allow
                our clients to flourish in their careers.
              </p>
            </Col>
          </Row>
        </ServicesSection>
        <ServicesInfoSection>
          <Row>
            <Accordion style={{ width: '100%' }} className="accordion">
              {accordion_row.map((row, index) => (
                <AccordionRow key={index} row={row} index={index} />
              ))}
            </Accordion>
          </Row>
        </ServicesInfoSection>
      </Container>
    </Styles>
  );
};

export default Services;
