import React, { useEffect, useState, useRef } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { isMobile } from "react-device-detect";

import styled from "styled-components";
import { ifProp } from "styled-tools";
import flexlogo from "../assets/flex-logo.png";

const Transition = styled.div`
  visibility: ${ifProp({ goingUp: true }, "visible", "hidden")};
  transition: all 400ms ease-in;
  transition: all 400ms ease-out;
  transition: top 0.3s;

  .initials {
    display: ${ifProp({ currentScrollY: 0 }, "visible", "none")};
  }

  .navbar {
    background-color: black;
    margin-bottom: 100px;
    transition: top 0.6s;
  }

  .navbar-brand,
  .navbar-nav .nav-link {
    color: white;
    font-family: "Roboto", sans-serif;
    font-size: 16px;

    &:hover {
      color: rgb(232, 150, 42);
    }
  }
  .navbar-toggler {
    border-color: white;
  }
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }
`;

const TitleDiv = styled.div`
  display: flex;
  flex-direction: ${ifProp({ currentScrollY: 0 }, "column", "row")};
  font-family: "Roboto", sans-serif;

  .first-leter {
    font-weight: 700;
  }
`;

export const NavBar = props => {
  const prevScrollY = useRef(0);
  const [goingUp, setGoingUp] = useState(true);
  const [currentScrollY, setCurrentScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY && goingUp) {
        setGoingUp(false);
      }
      if (prevScrollY.current > currentScrollY && !goingUp) {
        setGoingUp(true);
      }

      prevScrollY.current = currentScrollY;
      setCurrentScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [goingUp]);

  return (
    <Transition goingUp={goingUp} currentScrollY={currentScrollY}>
      <Navbar expand="lg fixed-top">
        <Navbar.Brand
          style={{ display: "flex", alignItems: "center" }}
          href="/"
        >
          <img
            src={flexlogo}
            width={currentScrollY > 0 ? "50" : "70"}
            height={currentScrollY > 0 ? "50" : "70"}
            className="d-inline-block align-top"
            alt="flex-basketball logo"
          />
          <TitleDiv currentScrollY={currentScrollY}>
            <div>
              <span className="first-leter">F</span>
              <span className="initials">lex</span>
            </div>
            <div>
              <span className="first-leter">B</span>
              <span className="initials">asketball</span>
            </div>
            <div>
              <span className="first-leter">M</span>
              <span className="initials">anagement</span>
            </div>
          </TitleDiv>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Item>
              <Nav.Link href="/">Home</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/about">About</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/services">Services</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/clients">Clients</Nav.Link>
            </Nav.Item>
            {isMobile && (
              <Nav.Item>
                <Nav.Link href="/contacts">Contacts</Nav.Link>
              </Nav.Item>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Transition>
  );
};
