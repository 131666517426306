import React, { useState } from "react";
import styled from "styled-components";
import { ifProp } from "styled-tools";
import { useHistory } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Image from "react-bootstrap/Image";

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 260px;
  width: 225px;
  margin: 10px 15px;
  text-align: center;

  h3 {
    color: white;
  }

  h4 {
    color: white;
  }

  &:hover {
    cursor: pointer;
  }
`;

const ClientInformation = styled.div`
  flex: 1;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(
      rgba(253, 164, 27, 0.9),
      rgba(253, 164, 27, 0.9)
    ),
    ${props => `url(${props.photo})`};
`;

const NameDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-top: none;
  border-radius: 0 0 4px 4px;
  flex: 1;
`;

const DotSigned = styled.div`
  height: 20px;
  width: 20px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  background-color: ${ifProp({ signed: true }, "red", "green")};
  margin-right: 5px;
`;

const StyledImage = styled(Image)`
  border-radius: 4px 4px 0 0;
`;

const Signed = styled.p`
  color: ${ifProp({ signed: true }, "rgba(94, 21, 19)", "green")};
  font-weight: bold;
`;

const shortName = name => {
  const splitName = name.split(" ");

  return name.length >= 13 ? splitName[0][0] + ". " + splitName[1] : name;
};

export default function ClientCard({ client }) {
  const { _id, name, position, signed, height, photo, passport } = client;

  const history = useHistory();
  const [hover, setHover] = useState(false);
  const positionLength = position.length;

  return (
    <CardContainer
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => history.push(`/clients/${_id}`)}
    >
      {!hover ? (
        <>
          <StyledImage src={photo} alt="player image" fluid />

          <NameDiv>
            <h3 style={{ flex: 1 }}>{shortName(name)}</h3>
            
            <OverlayTrigger>
              <OverlayTrigger
                delay={{ show: 250, hide: 400 }}
                placement="top"
                overlay={<Tooltip>{signed ? "Signed" : "Free"}</Tooltip>}
              >
                <DotSigned signed={signed} />
              </OverlayTrigger>
            </OverlayTrigger>
          </NameDiv>
        </>
      ) : (
        <ClientInformation photo={photo}>
          <div style={{ display: "flex" }}>
            {position.map((pos, index) => {
              if (index + 1 !== positionLength) {
                return <p key={`${index}${pos}`}>{pos}/</p>;
              } else {
                return <p key={`${index}${pos}`}>{pos}</p>;
              }
            })}
          </div>

          <div style={{ display: "flex" }}>
            {passport.map((pos, index) => {
              if (index + 1 !== positionLength) {
                return <p key={`${index}${pos}`}>{pos}</p>;
              } else {
                return <p key={`${index}${pos}`}>{pos}</p>;
              }
            })}
          </div>
          <p>{height / 100}m</p>
          <Signed signed={signed}>{signed ? "Signed" : "Free"}</Signed>
        </ClientInformation>
      )}
    </CardContainer>
  );
}
