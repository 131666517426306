import React from "react";
import Carousel from "react-bootstrap/Carousel";
import styled from "styled-components";

const Wrapper = styled.div`
  .max-size {
    max-height: 500px;
  }
`;

const PlayerName = styled.p`
  font-size: 20px;
  text-shadow: #000 -1px -1px;
`;

export default function CarouselPlayers({ images }) {
  return (
    <Wrapper>
      <Carousel>
        {images.map((image, index) => (
          <Carousel.Item key={index} className="max-size">
            <img
              className="d-block w-100 h-100"
              src={image.carouselImage}
              alt={image.name}
            />
            <Carousel.Caption>
              <h3>{image.title}</h3>
              <PlayerName>{image.name}</PlayerName>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </Wrapper>
  );
}
