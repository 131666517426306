import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import Form from "react-jsonschema-form";
import styled from "styled-components";
import gql from "graphql-tag";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";

export const CLIENTS_QUERY = gql`
  query ClientsQuery {
    clients {
      _id
      name
      position
      signed
      height
      weight
      photo
      videos
      dateOfBirth
      passport
      fbmPdf
      eurobasketPdf
      currentTeam
      carousel
      carouselImage
    }
  }
`;

const CREATE_CLIENT = gql`
  mutation CreateClient(
    $name: String!
    $signed: Boolean!
    $height: Float!
    $weight: Float!
    $photo: String!
    $dateOfBirth: String!
    $videos: [String!]!
    $stats: [StatInput!]
    $fbmPdf: String!
    $eurobasketPdf: String!
    $position: [String!]!
    $passport: [String!]!
    $currentTeam: String!
    $carousel: Boolean!
    $carouselImage: String!
  ) {
    createClient(
      clientInput: {
        name: $name
        signed: $signed
        height: $height
        weight: $weight
        photo: $photo
        dateOfBirth: $dateOfBirth
        videos: $videos
        stats: $stats
        fbmPdf: $fbmPdf
        eurobasketPdf: $eurobasketPdf
        position: $position
        passport: $passport
        currentTeam: $currentTeam
        carousel: $carousel
        carouselImage: $carouselImage
      }
    ) {
      name
    }
  }
`;

const UPDATE_CLIENT = gql`
  mutation UpdateClient(
    $clientId: String!
    $name: String!
    $signed: Boolean!
    $height: Float!
    $weight: Float!
    $photo: String!
    $dateOfBirth: String!
    $videos: [String!]!
    $stats: [StatInput!]
    $fbmPdf: String!
    $eurobasketPdf: String!
    $position: [String!]!
    $passport: [String!]!
    $currentTeam: String!
    $carousel: Boolean!
    $carouselImage: String!
  ) {
    updateClient(
      clientId: $clientId
      input: {
        name: $name
        signed: $signed
        height: $height
        weight: $weight
        photo: $photo
        dateOfBirth: $dateOfBirth
        videos: $videos
        stats: $stats
        fbmPdf: $fbmPdf
        eurobasketPdf: $eurobasketPdf
        position: $position
        passport: $passport
        currentTeam: $currentTeam
        carousel: $carousel
        carouselImage: $carouselImage
      }
    ) {
      name
    }
  }
`;

const GET_CLIENT_QUERY = gql`
  query getClient($clientId: String!) {
    client(clientId: $clientId) {
      name
      position
      signed
      height
      weight
      photo
      videos
      dateOfBirth
      passport
      fbmPdf
      eurobasketPdf
      currentTeam
      carousel
      carouselImage
      stats {
        pts
        twoFg
        threeFg
        freeThrowFg
        assists
        rebounds
        blockShots
        steals
        season
        games
        team
      }
    }
  }
`;

const Styles = styled.div`
  p {
    color: orange;
  }
`;

const getInitialValues = (clientState, selectedClientId) => {
  if (clientState.data && selectedClientId) {
    const newDate = new Date(Number(clientState.data.client.dateOfBirth))
      .toISOString()
      .split("T")[0];

    const newValues = { ...clientState.data.client, dateOfBirth: newDate };

    return newValues;
  } else {
    return {};
  }
};

export default function FormModal({
  show,
  handleClose,
  setShowToast,
  selectedClientId,
}) {
  const [createClient] = useMutation(CREATE_CLIENT, {
    refetchQueries: ["ClientsQuery"],
  });
  const [updateClient] = useMutation(UPDATE_CLIENT, {
    refetchQueries: ["ClientsQuery"],
  });
  const [getClient, clientState] = useLazyQuery(GET_CLIENT_QUERY);

  const handleSave = (form) => {
    const { formData } = form;
    const {
      name,
      signed,
      height,
      weight,
      photo,
      dateOfBirth,
      videos,
      position,
      passport,
      eurobasketPdf,
      fbmPdf,
      stats,
      currentTeam,
      carousel,
      carouselImage,
    } = formData;

    const variables = {
      name,
      signed,
      height,
      weight,
      photo,
      dateOfBirth: dateOfBirth.concat("T00:00:00.000Z"),
      videos,
      position,
      passport,
      eurobasketPdf,
      fbmPdf,
      stats,
      currentTeam,
      carousel,
      carouselImage,
    };

    console.log("formData", formData);

    if (selectedClientId) {
      const { stats } = variables;
      const newStats = stats.map(({ __typename, ...stats }) => stats);

      updateClient({
        variables: {
          ...variables,
          clientId: selectedClientId,
          stats: newStats,
        },
        options: {
          refetchQueries: [CLIENTS_QUERY],
        },
      });
    } else {
      createClient({
        variables,
        options: {
          refetchQueries: [CLIENTS_QUERY],
        },
      });
    }

    handleClose();
    setShowToast(true);
  };
  const uiSchema = {
    position: {
      "ui:widget": "checkboxes",
    },
    passport: {
      "ui:widget": "checkboxes",
    },
    signed: {
      "ui:widget": "select",
    },
    carousel: {
      "ui:widget": "select",
    },
    name: {
      "ui:placeholder": "Full name",
    },
    carouselImage: {
      "ui:placeholder": "Image to put on carousel",
    },
    videos: {
      items: {
        "ui:emptyValue": "",
      },
    },
  };

  const schema = {
    definitions: {
      Stat: {
        type: "object",
        properties: {
          season: {
            type: "string",
            title: "Season",
            maxLength: 5,
          },
          games: {
            type: "number",
            title: "Games",
            maximum: 100,
          },
          pts: {
            type: "number",
            title: "Points",
            maximum: 100,
          },
          twoFg: {
            type: "number",
            title: "2 Points %",
            maximum: 100,
          },
          threeFg: {
            type: "number",
            title: "3 Points %",
            maximum: 100,
          },
          freeThrowFg: {
            type: "number",
            title: "Free throws",
            maximum: 100,
          },
          assists: {
            type: "number",
            title: "Assists",
            maximum: 100,
          },
          rebounds: {
            type: "number",
            title: "Rebounds",
            maximum: 100,
          },
          blockShots: {
            type: "number",
            title: "Block Shots",
            maximum: 100,
          },
          steals: {
            type: "number",
            title: "Steals",
            maximum: 100,
          },
          team: {
            type: "string",
            title: "Team",
            maxLength: 50,
          },
        },
        required: [
          "season",
          "games",
          "pts",
          "twoFg",
          "threeFg",
          "freeThrowFg",
          "assists",
          "rebounds",
          "blockShots",
          "steals",
          "team",
        ],
      },
    },
    type: "object",
    required: [
      "name",
      "dateOfBirth",
      "signed",
      "fbmPdf",
      "eurobasketPdf",
      "weight",
      "height",
      "photo",
      "position",
      "passport",
      "videos",
      "stats",
      "currentTeam",
      "carousel",
      "carouselImage",
    ],
    properties: {
      name: {
        type: "string",
        title: "First name",
      },
      dateOfBirth: {
        title: "Date of Birth",
        type: "string",
        format: "date",
      },
      signed: {
        title: "Signed",
        type: "boolean",
      },
      fbmPdf: {
        title: "FBM profile",
        type: "string",
      },
      eurobasketPdf: {
        title: "Eurobasket profile",
        type: "string",
      },
      weight: {
        title: "Weight",
        type: "number",
        maximum: 150,
      },
      height: {
        title: "Height (in cm)",
        type: "number",
        maximum: 250,
      },
      photo: {
        title: "Photo",
        type: "string",
      },
      currentTeam: {
        title: "Current Team",
        type: "string",
      },
      position: {
        type: "array",
        title: "Positions",
        items: {
          type: "string",
          enum: ["Wing", "Guard", "Center", "Forward"],
        },
        uniqueItems: true,
      },
      passport: {
        type: "array",
        title: "Passport",
        items: {
          type: "string",
          enum: ["USA", "Bosman A", "Bosman B", "Cotonou"],
        },
        uniqueItems: true,
      },
      videos: {
        type: "array",
        maxItems: 4,
        title: "Videos",
        items: {
          type: "string",
        },
      },
      stats: {
        type: "array",
        title: "Stats",
        maxItems: 3,
        items: {
          $ref: "#/definitions/Stat",
        },
      },
      carousel: {
        type: "boolean",
        title: "Show in carousel?",
      },
      carouselImage: {
        type: "string",
        title: "Image to put in carousel",
      },
    },
  };

  useEffect(() => {
    if (selectedClientId) {
      getClient({
        variables: { clientId: selectedClientId },
      });
    }
  }, [selectedClientId, getClient]);

  if (clientState.loading) {
    return <div>LOADING...</div>;
  }

  return (
    <Styles>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            formData={getInitialValues(clientState, selectedClientId)}
            schema={schema}
            uiSchema={uiSchema}
            // onChange={console.log}
            onSubmit={handleSave}
            onError={() => {}}
            id="my-form"
          >
            <br />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" variant="success" form="my-form">
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Styles>
  );
}
