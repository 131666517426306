import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import { Container, Row, Col } from 'react-bootstrap';
import { useQuery } from '@apollo/react-hooks';
import { FaChevronLeft } from 'react-icons/fa';
import Image from 'react-bootstrap/Image';
import Loader from '../components/Loader';
import { IoIosArrowForward } from 'react-icons/io';

export const CLIENT_QUERY = gql`
  query LaunchDetails($clientId: String!) {
    client(clientId: $clientId) {
      name
      position
      signed
      height
      weight
      photo
      videos
      dateOfBirth
      passport
      fbmPdf
      eurobasketPdf
      currentTeam
      stats {
        pts
        twoFg
        threeFg
        freeThrowFg
        assists
        rebounds
        blockShots
        steals
        season
        games
        team
      }
    }
  }
`;

const Styles = styled.div`
  margin: 90px 0;
  padding-top: 10px;
  h1 {
    color: white;
    font-size: 100px;
  }
  .orange {
    color: rgb(232, 150, 42);
  }

  a {
    color: white;
    font-size: 20px;

    &:hover {
      color: rgb(232, 150, 42);
    }
  }
`;

const BackButton = styled.div`
  cursor: pointer;
  color: rgb(232, 150, 42);
`;

const InfoPlayer = styled.div`
  display: flex;
  justify-contente: center;
  align-items: center;
  text-align: center;

  .title {
    margin-right: 10px;
    color: rgb(232, 150, 42);
  }
`;

const Highlights = styled.h2`
  padding: 50px 0;

  .orange {
    color: orange;
  }
`;

const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  margin-bottom: 15px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Table = styled.table`
  text-align: center;
  color: white;
  width: 100%;

  .title {
    border-bottom: 3px solid white;
    color: orange;
  }

  .orange {
    color: orange;
  }

  th,
  td {
    padding: 5px;
  }
`;

function calculateAge(birthday) {
  var ageDifMs = Date.now() - new Date(birthday).getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

const ClientPage = (props) => {
  const clientId = props.match.params.client_id;
  const history = useHistory();
  const { loading, error, data } = useQuery(CLIENT_QUERY, {
    variables: { clientId },
  });

  if (loading) {
    return <Loader />;
  }

  if (!data || error) {
    console.log('ERROR', error);
    return <h1>no data</h1>;
  }

  const {
    name,
    position,
    signed,
    height,
    weight,
    photo,
    videos,
    dateOfBirth,
    passport,
    fbmPdf,
    eurobasketPdf,
    stats,
    currentTeam,
  } = data.client;
  const positionLength = position.length;
  const passportLength = passport.length;
  const videoslength = videos.length > 1 ? 6 : { span: 10, offset: 1 };

  return (
    <Styles>
      <Container>
        <Row>
          <Col md={{ span: 12 }}>
            <BackButton className="orange" onClick={() => history.goBack()}>
              <p className="orange">
                <FaChevronLeft />
                {' Back'}
              </p>
            </BackButton>
          </Col>
        </Row>
        <Row style={{ marginBottom: '50px' }}>
          <Col md={{ span: 4, offset: 1 }}>
            <Image src={photo} alt="player image" fluid rounded />
          </Col>

          <Col md={{ span: 5, offset: 1 }}>
            <h4 style={{ margin: '20px 0' }}>
              <span className="orange">__</span>
              {name}
            </h4>
            <InfoPlayer>
              <h5 className="title">Position:</h5>
              {position.map((pos, index) => {
                if (index + 1 !== positionLength) {
                  return <h5 key={index}>{pos}/</h5>;
                } else {
                  return <h5 key={index}>{pos}</h5>;
                }
              })}
            </InfoPlayer>
            <InfoPlayer>
              <h5 className="title">Height:</h5>
              <h5>{height / 100}m</h5>
            </InfoPlayer>
            <InfoPlayer>
              <h5 className="title">Weight:</h5>
              <h5>{weight}kg</h5>
            </InfoPlayer>
            <InfoPlayer>
              <h5 className="title">Age:</h5>
              <h5>{calculateAge(Number(dateOfBirth))}</h5>
            </InfoPlayer>
            <InfoPlayer>
              <h5 className="title">Current Team:</h5>
              <h5>{signed ? currentTeam ?? '---' : 'Free'}</h5>
            </InfoPlayer>
            <InfoPlayer>
              <h5 className="title">Passport:</h5>
              {passport.map((pos, index) => {
                if (index + 1 !== passportLength) {
                  return <h5 key={index + pos}>{pos}/</h5>;
                } else {
                  return <h5 key={index + pos}>{pos}</h5>;
                }
              })}
            </InfoPlayer>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <div
              style={{
                display: 'block',
                overflowX: 'auto',
                whiteSpace: 'nowrap',
              }}
            >
              <Table>
                <tbody>
                  <tr className="title">
                    {[
                      'Season',
                      'Team',
                      'G',
                      'PTS',
                      '2FG',
                      '3FG',
                      'FT',
                      'AST',
                      'REB',
                      'BLK',
                      'STL',
                    ].map((statTitle) => (
                      <th>
                        <h5 className="orange">{statTitle}</h5>
                      </th>
                    ))}
                  </tr>
                  {stats.map(
                    ({
                      season,
                      games,
                      pts,
                      twoFg,
                      threeFg,
                      freeThrowFg,
                      assists,
                      rebounds,
                      blockShots,
                      steals,
                      team,
                    }) => (
                      <tr>
                        <td>
                          <h5>{season ?? '-'}</h5>
                        </td>
                        <td>
                          <h5>{team ?? '-'}</h5>
                        </td>
                        <td>
                          <h5>{games ?? '-'}</h5>
                        </td>
                        <td>
                          <h5>{pts ?? '-'}</h5>
                        </td>
                        <td>
                          <h5>{twoFg ?? '-'}%</h5>
                        </td>
                        <td>
                          <h5>{threeFg ?? '-'}%</h5>
                        </td>
                        <td>
                          <h5>{freeThrowFg ?? '-'}%</h5>
                        </td>
                        <td>
                          <h5>{assists ?? '-'}</h5>
                        </td>
                        <td>
                          <h5>{rebounds ?? '-'}</h5>
                        </td>
                        <td>
                          <h5>{blockShots ?? '-'}</h5>
                        </td>
                        <td>
                          <h5>{steals ?? '-'}</h5>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </div>
          </Col>
          <Col
            md={{ span: 4, offset: 4 }}
            style={{
              marginTop: '10px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <a href={eurobasketPdf} target="_blank" rel="noopener noreferrer">
              Eurobasket profile <IoIosArrowForward />
            </a>
            <a href={fbmPdf} target="_blank" rel="noopener noreferrer">
              FBM profile <IoIosArrowForward />
            </a>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Highlights>
              <span className="orange">__</span>Highlights
            </Highlights>
          </Col>

          {videos.map((video, index) => (
            <Col key={index} md={videoslength} className="center">
              <VideoWrapper>
                <iframe
                  title={index}
                  width="560"
                  height="315"
                  src={`https://www.youtube.com/embed/${
                    video.split('watch?v=')[1]
                  }`}
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen="allowFullScreen"
                  frameBorder="0"
                ></iframe>
              </VideoWrapper>
            </Col>
          ))}
        </Row>
      </Container>
    </Styles>
  );
};

export default ClientPage;
