import React from "react";
import { Modal, Button } from "react-bootstrap";

export default function AreYouSureModal({
  deleteClient,
  handleClose,
  selectedClientId,
  selectedClientName,
  show
}) {
  return (
    <Modal size="md" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{`Delete ${selectedClientName}?`}</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            handleClose();
            deleteClient(selectedClientId);
          }}
        >
          Yes!
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
