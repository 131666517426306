import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import fiba from "../assets/fiba1.png";
import nba from "../assets/nba.png";

const Styles = styled.div`
  margin-top: 90px;
  h1 {
    color: white;
    font-size: 100px;
  }
`;

const ContactsSection = styled.section`
  padding-top: 50px;

  h1 {
    text-align: center;
    font-family: orbitron;
    font-size: 45px;
    color: white;
    margin-bottom: 50px;
  }

  p {
    color: white;
    font-family: Roboto;
    font-size: 16px;
    text-align: justify;
  }

  .orange {
    color: rgb(232, 150, 42);
  }
  .margins {
    margin: 40px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .alignmentRow {
    align-items: center;
    justify-content: center;
    display: flex;
  }
`;

const Contacts = () => {
  return (
    <Styles>
      <Container>
        <ContactsSection>
          <Row>
            <Col>
              <h1>
                Contacts <span className="orange">__</span>
              </h1>
              <Row>
                <div className="contacts"></div>
                <Col md={6}>
                  <p>
                    <span>USA Office</span>
                    <br /> <span>+1 267 549 5262 </span>
                    <br />
                    <span>gjavardian@flexbball.com</span>
                  </p>
                </Col>
                <Col md={6}>
                  <p>
                    Europe Office <br />
                    +351 918 253 227 <br />
                    nunopedroso@flexbball.com
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className="margins">
              <img src={fiba} height={50} alt="FIBA logo" />
              <img src={nba} height={50} alt="NBA logo" />
            </Col>
          </Row>
        </ContactsSection>
      </Container>
    </Styles>
  );
};

export default Contacts;
