import React from 'react';
import { Card, Accordion } from 'react-bootstrap';
import { FaAngleDown } from 'react-icons/fa';
import styled from 'styled-components';

const Wrapper = styled(Card)`
  &:hover {
    cursor: pointer;
  }

  .accordion {
    background-color: transparent;
  }

  .accordion_card {
    background-color: rgba(0, 0, 0, 0.85);
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: left;
    font-weight: lighter;
  }

  .expandicon {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .cardBodyText {
    text-align: justify;
  }
`;

const AccordionRow = ({ row, index }) => {
  return (
    <Wrapper>
      <Accordion.Toggle
        as={Card.Header}
        eventKey={index}
        className="accordion_card"
      >
        <div className="expandicon">
          {row.title}
          <FaAngleDown />
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={index}>
        <Card.Body className="cardBodyText">{row.desc}</Card.Body>
      </Accordion.Collapse>
    </Wrapper>
  );
};

export default AccordionRow;
