import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CarouselPlayers from "../components/CarouselPlayers";
import styled from "styled-components";
import court from "../assets/court.jpg";
import Image from "react-bootstrap/Image";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

const CLIENTS_QUERY = gql`
  query ClientsQuery {
    clients {
      _id
      name
      carousel
      carouselImage
    }
  }
`;

const Styles = styled.div`
  .home-image {
    position: relative;
    margin-top: 100px;
    text-align: center;
  }

  .orange {
    color: rgb(232, 150, 42);
  }

  .slogan {
    position: absolute;
    bottom: 30%;
    left: 25%;

    h1 {
      color: white;
      font-size: 100px;
    }

    @media only screen and (max-width: 600px) {
      h1 {
        color: white;
        font-size: 25px;
      }
      left: 30%;
    }
  }
`;

const ArrowDown = styled.div`
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  border-top: 30px solid rgb(232, 150, 42);
  cursor: pointer;
`;

// const SocialMedia = styled.section`
//   margin-top: 50px;
//   margin-bottom: 50px;

//   h1 {
//     font-family: orbitron;
//     font-size: 50px;
//     color: white;
//   }
// `;

const WhoWeAreRow = styled(Row)`
  margin: 30px 0;

  h2 {
    color: white;
    margin-bottom: 25px;
  }

  p {
    text-align: justify;
  }
`;

const Home = () => {
  let myDivToFocus = React.createRef();

  const { loading, data } = useQuery(CLIENTS_QUERY);

  const handleOnClick = (event) => {
    if (myDivToFocus.current) {
      myDivToFocus.current.scrollIntoView(true, {
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  return (
    <Styles>
      <Container>
        <Row>
          <Col md={12}>
            <div className="home-image">
              <h4
                style={{
                  position: "absolute",
                  bottom: "10%",
                  left: "0",
                  right: "0",
                }}
              >
                It never gets easier, you just get better
              </h4>
              <Image src={court} fluid />
            </div>
          </Col>
        </Row>
        <div ref={myDivToFocus} />
        <ArrowDown onClick={() => handleOnClick()} />
        <WhoWeAreRow>
          <Col md={12}>
            <h2>
              Who We Are<span className="orange">__</span>
            </h2>
            <p>
              <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                Flex Basketball Management
              </span>{" "}
              is a highly dedicated sports agency that offers our athletes a
              full range of services when representing the careers of our
              clients all across the globe. With years of collective experience,
              we provide professional guidance and attention to each of our
              clients where loyalty, integrity, and work ethic are core values.{" "}
              <span style={{ fontWeight: "bold", fontSize: "18px" }}>FBM</span>{" "}
              has a global network of personnel working in different locations
              around the world to make sure our clients are fully covered.
            </p>
          </Col>
        </WhoWeAreRow>

        <Row>
          <Col md={12}>
            {loading ? (
              <p>loading...</p>
            ) : (
              <CarouselPlayers
                images={data.clients.filter((client) => client.carousel)}
              />
            )}
          </Col>
        </Row>
      </Container>
    </Styles>
  );
};

export default Home;
