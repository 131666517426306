import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import styled from "styled-components";
import nunoPedrosoPic from "../assets/nunoPedrosoCrop.png";
import gregJavardianPic from "../assets/gregJavardian_crop.png";
// import juliusKim from "../assets/juliusKim.jpeg";

const Styles = styled.div`
  margin-top: 90px;
  h1 {
    color: white;
    font-size: 100px;
  }
`;

const AboutUsSection = styled.section`
  padding-top: 50px;

  h1 {
    text-align: center;
    font-family: orbitron;
    font-size: 45px;
    color: white;
    margin-bottom: 50px;
  }

  p {
    color: white;
    font-family: Roboto;
    font-size: 16px;
    text-align: justify;
  }

  .orange {
    color: rgb(232, 150, 42);
  }
`;

const OurTeamSection = styled.section`
  padding-top: 50px;

  h1 {
    font-family: orbitron;
    font-size: 45px;
    color: white;
    margin-bottom: 50px;
  }

  h2 {
    font-family: orbitron;
    font-size: 35px;
    color: white;
    margin-bottom: 50px;
  }

  p {
    color: white;
    font-family: Roboto;
    font-size: 16px;
    text-align: justify;
  }

  .orange {
    color: rgb(232, 150, 42);
  }

  .personPosition {
    text-align: right;
  }

  .position {
    font-size: 25px;
  }

  .name {
    font-size: 30px;
  }

  .desc {
    font-size: 20px;
  }

  .image {
    height: 400px;
    wigth: 100%;
  }

  .margin-bellow {
    margin-bottom: 20px;
  }
`;

const About = () => {
  return (
    <Styles>
      <Container>
        <AboutUsSection>
          <Row>
            <Col md={12}>
              <h1>
                __About <span className="orange">Us</span>
              </h1>
              <p>
                <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                  Flex Basketball Management
                </span>{" "}
                prides itself on the preparation of our clients with passion,
                dedication, and integrity. With years of collective experience
                procuring and negotiating contracts for our clients in many of
                the most competitive and prestigious leagues around the world,
                we provide professional guidance and attention to each of our
                clients that is focused on optimizing talent on the court and
                ensuring comfort and development off it.{" "}
              </p>
              <p>
                <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                  FBM
                </span>{" "}
                personnel has earned a reputation for providing a loyal
                environment and the highest quality representation, where
                clients are always the top priority and considered part of the
                TEAM
              </p>
            </Col>
          </Row>
        </AboutUsSection>
        <OurTeamSection>
          <Row className="margin-bellow">
            <Col xs={{ span: 12, order: 1 }} md={{ span: 8 }}>
              <div className="personPosition">
                <p className="orange position">President</p>
                <p className="name">Nuno Pedroso</p>
              </div>
              <p>
                Nuno is FIBA Certified Agent and a former 15-year professional
                basketball player who played in several top leagues throughout
                Europe. Nuno also has a Master’s Degree in Physical Education
                and Sports by the Instituto Superior de Maia (Portugal) and the
                Executive Master in Marketing and Sports Management by INDEG-IUL
                / ISCTE Executive Education – University of Lisbon (Portugal).
                As a former player, Nuno has a deep understanding of the game
                and what is required to have success as a professional
                basketball player. Nuno speaks fluent Portuguese, Spanish, and
                English.​
              </p>
            </Col>
            <Col md={{ span: 3, order: 1 }}>
              <Image src={nunoPedrosoPic} alt="Nuno Pedroso" fluid />
            </Col>
          </Row>

          <Row className="margin-bellow">
            <Col md={3}>
              <Image
                src={gregJavardianPic}
                alt="Greg Javardian"
                rounded
                fluid
              />
            </Col>
            <Col md={{ span: 8 }} xs={{ span: 12, order: 1 }}>
              <div>
                <p className="orange position">Vice President</p>
                <p className="name">GREG JAVARDIAN​</p>
              </div>
              <p>
                Greg is a NBPA certified Agent and has worked in basketball
                scouting and player management for the past decade. He holds a
                Sports Management degree from Temple University in Philadelphia
                and a law degree from Drexel University in Philadelphia. Greg’s
                responsibilities include recruitment, contract negotiation,
                marketing, and handling legal matters. Given his extensive
                basketball and legal backgrounds, Greg is perfectly situated to
                understand how to manage and protect players throughout their
                careers. ​
              </p>
            </Col>
          </Row>
          {/* <Row className="margin-bellow">
              <Col xs={{ span: 12, order: 1 }} md={{ span: 12 }}>
                <div style={{ textAlign: 'center' }}>
                  <h2>
                    Staff<span className="orange">__</span>
                  </h2>
                </div>
              </Col>
            </Row>
            <Row className="margin-bellow">
              <Col xs={{ span: 12, order: 1 }} md={{ span: 8 }}>
                <div className="personPosition">
                  <p className="orange position">Scouter/Recruiter</p>
                  <p className="name">Julius Kim</p>
                </div>
                <p>
                  Julius graduated from St. John’s University in New York with a
                  degree in journalism. During his time in college he worked with
                  high-level independent grassroots tournaments, Phenom Hoop
                  Report and Elevate Hoops, where he scouted and developed
                  relationships with college coaches and players.
                </p>
              </Col>
              <Col md={{ span: 3, order: 1 }}>
                <Image src={juliusKim} alt="Julius Kim" fluid />
              </Col>
            </Row> */}
        </OurTeamSection>
      </Container>
    </Styles>
  );
};

export default About;
