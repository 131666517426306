import React, { Component } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { FaFacebookSquare, FaInstagram, FaTwitterSquare } from "react-icons/fa";
import fiba from "../assets/fiba1.png";
import nba from "../assets/nba.png";

const Styles = styled(Container)`
  color: white;

  p {
    font-size: 10px;
  }
     
  .borderTop {
    border-top: 1px solid rgb(232, 150, 42);
    margin-top: 10px;
  }

  .title {
    font-size: 15px;
    color: white;
    font-family: orbitron;
  }
  .orange {
    font-size: 15px;
    color: rgb(232, 150, 42);
    font-family: orbitron;
  }
  .photo {
    border: 2px solid transparent;
    font-size: 35px;
    margin: 0 10px;
  }
  .icon {
    color: white;
  }
  .alignmentRow {
    display: flex,
    justifyContent: center,
    alignItems: center
  }

  .bgBlack {
    background-color: rgb(0,0,0,0.8);
  }
`;

export default class Footer extends Component {
  render() {
    return (
      <Styles>
        <Row style={{ padding: "10px" }} className="borderTop bgBlack">
          <Col md={2} className="margin">
            <p className="orange">
              __Contact
              <span className="title"> Us</span>
            </p>
          </Col>
          <Col md={2} className="margin">
            <p>
              USA Office <br />
              +1 267 549 5262 <br />
              gjavardian@flexbball.com
            </p>
          </Col>
          <Col md={2} className="margin">
            <p>
              Europe Office <br />
              +351 918 253 227 <br />
              nunopedroso@flexbball.com
            </p>
          </Col>
          <Col md={{ span: 5, offset: 1 }} className="margin">
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center"
              }}
            >
              <a
                className="icon"
                target="_blank"
                href="https://www.facebook.com/nuno.pedroso.94"
                rel="noopener noreferrer"
              >
                <FaFacebookSquare className="photo" />
              </a>
              <a
                className="icon"
                target="_blank"
                href="https://twitter.com/flexbball"
                rel="noopener noreferrer"
              >
                <FaTwitterSquare className="photo" />
              </a>
              <a
                className="icon"
                target="_blank"
                href="https://www.instagram.com/flexbball/"
                rel="noopener noreferrer"
              >
                <FaInstagram className="photo" />
              </a>

              <img src={fiba} height={40} alt="FIBA logo" />

              <img src={nba} height={40} alt="NBA logo" />
            </div>
          </Col>
        </Row>
      </Styles>
    );
  }
}
