import React, { Component } from "react";
import styled from "styled-components";
import { Container, Row } from "react-bootstrap";
import { FaFacebookSquare, FaInstagram, FaTwitterSquare } from "react-icons/fa";

const Wrapper = styled(Container)`
  color: white;
  border-top: 1px solid rgb(232, 150, 42);
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 5px;

  .photo {
    border: 2px solid transparent;
    font-size: 40px;
    margin: 0 10px;
  }
  .icon {
    color: white;
  }
`;

export default class Footer extends Component {
  render() {
    return (
      <Wrapper>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "5px"
          }}
        >
          <div>
            <a
              className="icon"
              target="_blank"
              href="https://www.facebook.com/nuno.pedroso.94"
              rel="noopener noreferrer"
            >
              <FaFacebookSquare className="photo" />
            </a>
            <a
              className="icon"
              target="_blank"
              href="https://twitter.com/flexbball"
              rel="noopener noreferrer"
            >
              <FaTwitterSquare className="photo" />
            </a>
            <a
              className="icon"
              target="_blank"
              href="https://www.instagram.com/flexbball/"
              rel="noopener noreferrer"
            >
              <FaInstagram className="photo" />
            </a>
          </div>
        </Row>
      </Wrapper>
    );
  }
}
