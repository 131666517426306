import React, { useState } from "react";
import { Container, Row, Col, Table, Button, Toast } from "react-bootstrap";
import styled from "styled-components";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/react-hooks";
import FormModal from "../components/FormModal";
import Loader from "../components/Loader";
import VerificationModal from "../components/VerificationModal";
import AreYouSureModal from "../components/AreYouSureModal";
import { Link } from "react-router-dom";
import { MdClear, MdCreate } from "react-icons/md";

export const CLIENTS_QUERY = gql`
  query ClientsQuery {
    clients {
      _id
      name
      position
      signed
      height
      weight
      photo
      videos
      dateOfBirth
      passport
      fbmPdf
      eurobasketPdf
      currentTeam
      carousel
      carouselImage
    }
  }
`;

const DELETE_CLIENTE_QUERY = gql`
  mutation deleteClient($clientId: String!) {
    deleteClient(clientId: $clientId) {
      _id
      name
    }
  }
`;

const Styles = styled.div`
  margin-top: 90px;

  .orange {
    color: orange;
  }
`;

const ManageClients = () => {
  const [showToast, setShowToast] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedClientName, setSelectedClientName] = useState("");
  const [selectedClientId, setSelectedClientId] = useState("");
  const [showAreYouSureModal, setShowAreYouSureModal] = useState(false);
  const handleClose = () => {
    setSelectedClientName("");
    setSelectedClientId("");
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const [authenticated, setAuthenticated] = useState(false); //false
  const [showVerification, setshowVerification] = useState(true); //true
  const handleCloseVerification = () => setshowVerification(false);
  const handleCloseAreYouSure = () => {
    setSelectedClientName("");
    setSelectedClientId("");
    setShowAreYouSureModal(false);
  };
  const { loading, error, data } = useQuery(CLIENTS_QUERY);
  const [deleteClient] = useMutation(DELETE_CLIENTE_QUERY, {
    refetchQueries: ["ClientsQuery"],
  });

  if (loading) {
    return <Loader />;
  }

  if (!data || error) {
    return (
      <Styles>
        <Container>
          <h4 styles={{ marginTop: "400px" }}>no data</h4>
        </Container>
      </Styles>
    );
  }

  return (
    <Styles>
      <Container>
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={4000}
          autohide
        >
          <Toast.Header>
            <strong className="mr-auto">Client Added</strong>
            <small>now</small>
          </Toast.Header>
        </Toast>

        <FormModal
          selectedClientId={selectedClientId}
          setShowToast={setShowToast}
          show={show}
          handleClose={handleClose}
        />

        <VerificationModal
          show={showVerification}
          handleClose={handleCloseVerification}
          setAuthenticated={setAuthenticated}
        />

        <AreYouSureModal
          show={showAreYouSureModal}
          handleClose={handleCloseAreYouSure}
          deleteClient={() =>
            deleteClient({
              variables: { clientId: selectedClientId },
            })
          }
          selectedClientName={selectedClientName}
          selectedClientId={selectedClientId}
        />

        <Row>
          <Col md={12}>
            <h2 style={{ marginTop: "10px", marginBottom: "20px" }}>
              Manage Clients<span className="orange">__</span>
            </h2>
            {!authenticated && (
              <Button
                variant="success"
                onClick={() => setshowVerification(true)}
              >
                {"Authenticat >"}
              </Button>
            )}
          </Col>
          {authenticated && (
            <>
              <Col md={{ span: 12 }} style={{ marginBottom: "20px" }}>
                <Button variant="success" onClick={handleShow}>
                  Add Client +
                </Button>
              </Col>

              <Col md={{ span: 12 }}>
                <div
                  style={{
                    display: "block",
                    overflowX: "auto",
                    whiteSpace: "nowrap",
                  }}
                >
                  <Table
                    striped
                    bordered
                    hover
                    variant="dark"
                    style={{ textAlign: "center" }}
                  >
                    <thead>
                      <tr>
                        <th>Delete/Edit</th>
                        <th>#</th>
                        <th>Name</th>
                        <th>Team</th>
                        <th>FBM Profile</th>
                        <th>EuroBasket Profile</th>
                        <th>position</th>
                        <th>signed</th>
                        <th>height</th>
                        <th>weight</th>
                        <th>photo</th>
                        <th>videos</th>
                        <th>dateOfBirth</th>
                        <th>passport</th>
                        <th>carousel</th>
                        <th>carouselImage</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.clients.map((client) => (
                        <tr key={client._id}>
                          <td>
                            <Button
                              variant="danger"
                              onClick={() => {
                                setSelectedClientName(client.name);
                                setSelectedClientId(client._id);
                                setShowAreYouSureModal(true);
                              }}
                            >
                              <MdClear />
                            </Button>

                            <Button
                              style={{ marginLeft: "5px" }}
                              variant="warning"
                              onClick={() => {
                                setShow(true);
                                setSelectedClientName(client.name);
                                setSelectedClientId(client._id);
                              }}
                            >
                              <MdCreate />
                            </Button>
                          </td>
                          <td>
                            <Link to={`/clients/${client._id}`}>
                              ...{client._id.slice(4, 10)}...
                            </Link>
                          </td>
                          <td>{client.name}</td>
                          <td>{client.currentTeam ?? "-"}</td>
                          <td>
                            {client.fbmPdf ? (
                              <a
                                href={client.fbmPdf}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                ...
                                {client.fbmPdf.split("/").pop()}
                              </a>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td>
                            {client.eurobasketPdf ? (
                              <a
                                href={client.eurobasketPdf}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                ...
                                {client.eurobasketPdf.split("/").pop()}
                              </a>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td>
                            {client.position
                              ? client.position.map((pos) => `${pos} `)
                              : "-"}
                          </td>
                          <td>{client.signed ? "Signed" : "Free"}</td>
                          <td>{client.height ?? "-"}</td>
                          <td>{client.weight ?? "-"}</td>
                          <td>
                            <a
                              href={client.photo}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {client.photo ?? "-"}
                            </a>
                          </td>
                          <td>
                            {client.videos
                              ? client.videos.map((video) => (
                                  <a
                                    key={video}
                                    href={video}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    ...
                                    {video.slice(32, -1)}{" "}
                                  </a>
                                ))
                              : "-"}
                          </td>
                          <td>
                            {client.dateOfBirth ? client.dateOfBirth : "-"}
                          </td>
                          <td>
                            {client.passport
                              ? client.passport.map(
                                  (passaport) => `${passaport} `
                                )
                              : "-"}
                          </td>
                          <td>{client.carousel ? "Yes" : "No"}</td>
                          <td>
                            <a
                              href={client.carouselImage}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {client.carouselImage ?? "-"}
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </>
          )}
        </Row>
      </Container>
    </Styles>
  );
};

export default ManageClients;
