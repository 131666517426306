import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { isMobile } from "react-device-detect";

import Layout from "./components/Layout";
import { NavBar } from "./components/NavBar";
import Footer from "./components/Footer";
import FooterMobile from "./components/FooterMobile";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Clients from "./pages/Clients";
import Contacts from "./pages/Contacts";
import ClientPage from "./pages/ClientPage";
import ManageClients from "./pages/ManageClients";
import { GlobalStyle } from "./styles/GlobalSyles";
import "./index.css";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
ReactGA.pageview(window.location.pathname + window.location.search);

const client = new ApolloClient({
  uri: "/graphql"
});

function App() {
  return (
    <ApolloProvider client={client}>
      <Helmet>
        <title>Flex Basketball Management</title>
        <meta
          name="description"
          content="Flex Basketball Management is a highly dedicated sports agency that offers our athletes a full range of services when representing the careers of our clients all across the globe"
        />
        <meta name="theme-color" content="#ffa500" />
      </Helmet>
      <NavBar />
      <GlobalStyle />
      <Layout>
        <Router>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/services" component={Services} />
          <Route exact path="/clients" component={Clients} />
          <Route exact path="/manage-clients" component={ManageClients} />
          <Route exact path="/clients/:client_id" component={ClientPage} />
          {isMobile && <Route exact path="/contacts" component={Contacts} />}
        </Router>
        {isMobile ? (
          <div>
            <FooterMobile />
          </div>
        ) : (
          <Footer />
        )}
      </Layout>
    </ApolloProvider>
  );
}

export default App;
