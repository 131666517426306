import React, { useState } from 'react';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Loader from '../components/Loader';
import ClientCard from '../components/ClientCard';
import Select from 'react-select';
import { isMobile } from 'react-device-detect';

const CLIENTS_QUERY = gql`
  query ClientsQuery {
    clients {
      _id
      name
      position
      signed
      height
      weight
      photo
      videos
      dateOfBirth
      passport
    }
  }
`;

const Styles = styled.div`
  margin-top: 90px;
  padding-top: 10px;
  h1 {
    color: white;
    font-size: 100px;
  }

  .nav-tabs > a {
    color: white;
  }

  .nav-tabs > a.active {
    background-color: transparent !important;
    color: rgb(232, 150, 42);
    border-bottom: 1px solid black;
  }
`;

const ClientsTitle = styled.h2`
  padding: 50px 0;

  .orange {
    color: rgb(232, 150, 42);
  }
`;

const ClientsCards = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 0;

  @media only screen and (max-width: 600px) {
    justify-content: center;
  }
`;

const Clients = () => {
  const { loading, error, data } = useQuery(CLIENTS_QUERY);
  const [key, setKey] = useState('all');
  const sortArrayByName = (array) => {
    return array.sort(function (a, b) {
      var nameA = a.name.toLowerCase(),
        nameB = b.name.toLowerCase();
      return nameA < nameB ? 1 : -1;
    });
  };

  const sortArrayBySigned = (array) => {
    return array.sort((a, b) => (a.signed > b.signed ? 1 : -1));
  };

  const options = [
    { value: 'all', label: 'All' },
    { value: 'guards', label: 'Guards' },
    { value: 'forwardsWings', label: 'Forwards / Wings' },
    { value: 'centers', label: 'Centers' },
  ];

  if (loading) {
    return <Loader />;
  }

  if (!data || error) {
    return (
      <Styles>
        <Container>
          <h4 styles={{ marginTop: '400px' }}>no data</h4>
        </Container>
      </Styles>
    );
  }

  const allList = sortArrayBySigned(sortArrayByName(data.clients));

  const getListOfClients = (key) => {
    if (key === 'guards') {
      return allList.filter((client) => client.position.includes('Guard'), []);
    }

    if (key === 'forwardsWings') {
      return allList.filter(
        (client) =>
          client.position.includes('Forward') ||
          client.position.includes('Wing'),
        []
      );
    }

    if (key === 'centers') {
      return allList.filter((client) => client.position.includes('Center'), []);
    }

    return allList;
  };

  return (
    <Styles>
      <Container>
        <Row>
          <Col md={12}>
            <ClientsTitle>
              Clients<span className="orange">__</span>
            </ClientsTitle>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            {isMobile ? (
              <>
                <Select
                  value={key}
                  onChange={(k) => setKey(k.value)}
                  options={options}
                  defaultValue={key}
                  isSearchable={false}
                  placeholder={
                    options.find((option) => key === option.value).label
                  }
                />

                <ClientsCards>
                  {getListOfClients(key).map((client) => (
                    <ClientCard key={client._id} client={client} />
                  ))}
                </ClientsCards>
              </>
            ) : (
              <Tabs
                fill
                justify
                defaultActiveKey={key}
                activeKey={key}
                id="noanim-tab-example"
                onSelect={(k) => setKey(k)}
              >
                {options.map((option, index) => (
                  <Tab key={index} eventKey={option.value} title={option.label}>
                    <ClientsCards>
                      {getListOfClients(key).map((client) => (
                        <ClientCard key={client._id} client={client} />
                      ))}
                    </ClientsCards>
                  </Tab>
                ))}
              </Tabs>
            )}
          </Col>
        </Row>
      </Container>
    </Styles>
  );
};

export default Clients;
