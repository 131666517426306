import React from "react";
import { Modal, Button } from "react-bootstrap";
import Form from "react-jsonschema-form";

export default function VerificationModal({
  show,
  handleClose,
  setAuthenticated,
}) {
  const schema = {
    type: "object",
    properties: {
      email: {
        format: "email",
        title: "Email",
        type: "string",
        minLength: 3,
      },
      password: {
        title: "Password",
        type: "string",
        minLength: 3,
      },
    },
    required: ["password", "email"],
  };
  const uiSchema = {
    password: {
      "ui:widget": "password",
    },
  };
  const handleSubmit = ({ formData }) => {
    if (
      process.env.REACT_APP_PASSWORD === formData.password &&
      process.env.REACT_APP_EMAIL === formData.email
    ) {
      setAuthenticated(true);
      handleClose();
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Authetication</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          schema={schema}
          uiSchema={uiSchema}
          onSubmit={handleSubmit}
          id="auth-form"
        >
          <br />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" type="submit" form="auth-form">
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
