import React from "react";
import { Container, Spinner } from "react-bootstrap";
import styled from "styled-components";

const Styles = styled.div`
  margin-top: 90px;
  display: flex;
  align-items: center;
`;

const Loader = () => {
  return (
    <Styles>
      <Container style={{ textAlign: "center", marginTop: "50px" }}>
        <Spinner animation="grow" variant="warning" />
        <Spinner animation="grow" variant="warning" />
        <Spinner animation="grow" variant="warning" />
      </Container>
    </Styles>
  );
};

export default Loader;
