import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }
  body {
    background-color: black;
    scroll-behavior: smooth;
    h2 {
        font-family: orbitron;
        font-size: 45px;
        color: white;

        @media only screen and (max-width: 600px) {
          font-size: 30px;
        }
    }

    h4 {
      font-family: orbitron;
      font-size: 35px;
      color: white;

      @media only screen and (max-width: 600px) {
        font-size: 25px;
      }
    }

    h5 {
      font-family: Roboto;
      font-size: 30px;
      color: white;

      @media only screen and (max-width: 600px) {
        font-size: 20px;
      }
    }

    p {
        color: white;
        font-family: Roboto;
        font-size: 16px;
      }
    

    form {
      #root_passport,#root_position {
        span {
          margin-left: 5px;
        }
        display: flex;
        justify-content: space-between;
      }
      .btn-add::after {
        content: "Add +";
      }
      .array-item-move-up::after {
        content: "<-";
      }
      .array-item-move-down::after {
        content: "->";
      }
      .array-item-remove::after {
        content: "Remove";
      }
    }
  }
`;
